

.details-container {
  line-height: 40px;
}

.location-box {
  padding-left: 15px;
  border-left: 0.3rem solid;
}

.type-detail {
  color: grey
}

.location-icon {
  color: black !important
}

.location-text {
  text-decoration: none !important;
  color: #000 !important;
}
.session-head {
  display: inline-block;
  border-bottom: none;
  position: relative;

  .code-wrapper {
    position: absolute;
    left: 0px;
    height: 100%;
    width: 8px;
    border-radius: 5px;
  }


  .item-header-gradient {
    position: absolute;
    //z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url('../../assets/shadow.png') no-repeat bottom center;
    background-size: cover;
  }

  .title {
    font-size: 1.1rem;
    line-height: 1.3rem;
  }

  .type {
    font-size: 0.9rem;
    display: block;
    line-height: 1rem;

    strong {
      display: inline-block;
      font-size: 0.7rem;
    }
  }

  .time {
    font-size: 0.9rem;
  }
}


.session-corner-annotation {
  background-image: linear-gradient(to right, rgb(197, 50, 50), rgb(179, 68, 68));
  position: absolute;
  top: -15px;
  right: -40px;
  width: 100px;
  height: 50px;
  -webkit-transform: rotate(45deg);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.annotation-text {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  color: white;
}

.day-selector {
  width: 100%;
  padding: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.day-selector:hover {
  cursor: pointer;
}

.day-selector-item {
  text-align: center;
  width: 100%;
  position: relative;
  display: block;
}


.day-text {
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
}

.day-number {
  width: 100%;
  color: white;

}

.day-number-selected {
}

.punkt-selected {
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}

.large-selected {
  font-weight: 900;
}


